export const getInitialTheme = () => {
  if (typeof window === 'undefined') {
    return "dark"; // SSR을 고려하여 기본값 설정
  }
  
  // 로컬 스토리지에서 테마 값 읽기
  const savedTheme = localStorage.getItem("theme");
  
  // 저장된 테마가 있으면 해당 값을, 없으면 기본값으로 "dark"를 사용
  return savedTheme || "dark";
};

export const initialState = {
  theme: getInitialTheme(),
};

// action type 정의
export const SET_LIGHT = "setLight"
export const SET_DARK = "setDark"

// action 정의
export const setLight = {
  type: SET_LIGHT,
  data: {
    theme: "light",
  },
}

export const setDark = {
  type: SET_DARK,
  data: {
    theme: "dark",
  },
}

// reducer 정의
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LIGHT: {
      return {
        ...state,
        theme: "light",
      }
    }
    case SET_DARK: {
      return {
        ...state,
        theme: "dark",
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}

export default reducer
